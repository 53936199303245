@greenMain: #008738;
@greenLight: #4aba72;
@white: #FAFAFA;
@greyMain: #eee;

body {
  position: relative;
  padding-top: 1px;
  margin-top: -1px;
  min-width: 1000px;
}

#top {
  background-color: #f8f8f8;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 3px 15px;
  margin-bottom: 7px;
}

#top navbar {
  margin-bottom: 0;
}

:target::before {
  content: '';
  display: block;
  height:      120px;
  margin-top: -120px;
}

.festival-sidenav h4,
.table-of-contents h4 {
  margin-top: 0;
}

.festival-sidenav a,
.table-of-contents a {
  color: @greenMain;
}

.festival-sidenav a.active,
.table-of-contents a.active {
  background-color: @greenLight;
}

.festival-sidenav a.active:hover,
.table-of-contents a.active:hover {
  text-decoration: none;
  background-color: @greenMain;
  color: @white;
}

.festival-sidenav .list-group-item,
.festival-sidenav .nav > li > a,
.table-of-contents .list-group-item,
.table-of-contents .nav > li > a {
  padding: 4px 7px;
}

.festival-sidenav .subMenu,
.table-of-contents .subMenu {
  display: none;
  padding-left: 20px;
}

.festival-sidenav .subMenu p,
.table-of-contents .subMenu p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.festival-sidenav .subMenu .active,
.table-of-contents .subMenu .active {
  text-decoration: none;
  background-color: @greenMain;
  color: @white;
  width: 100%;
}

.festival-sidenav .subMenu .active a,
.table-of-contents .subMenu .active a {
  color: inherit;
}

.festival-sidenav .list-group-item.active + .subMenu,
.table-of-contents .list-group-item.active + .subMenu {
  display: block;
}

article {
  font-size: 14px;
  overflow: hidden;
}

article a {
  color: @greenMain;
}

article nav {
  text-align: center;
}

article nav .pagination .active a:focus,
article nav .pagination .active a:hover,
article nav .pagination .active span,
article nav .pagination .active span:focus,
article nav .pagination .active span:hover {
  background-color: @greenMain;
  color: @white;
}

article nav .pagination .page {
  color: @greenMain;
}

article table td, article table th {padding:5px !important;}
article table {margin-bottom:20px !important;}

// #header {
//     .logo, .reg-buttons, .offOnMobile {
//         display: inline-block;
//     }
//     .logo {
//         padding-left: 20px;
//     }
// }
//
// #header-links {
//   padding: 5px 10px;
//   font-size: 14pt;
//   text-align: right;
// }
//
// #header-links a {
//   text-decoration: none;
//   color: @greenMain;
// }
//
// #header {
//   /*height: 100px;
//   background-image: url('../img/header.png');
//   background-repeat: no-repeat;
//   background-color: @greenMain;*/
//   margin-bottom: 5px;
// }
//
// #header .img {
//   /*width: 240px;*/
//   height: 61px;
// }
//
// #logo {
//   opacity: 0;
//   display: block;
//   position: absolute;
//   width: 180px;
//   height: 100px;
// }
//
// #header-search-form form {
//   margin-bottom: 0;
// }
//
// #header-search-form {
//   float: right;
//   margin-top: 15px;
//   margin-right: 10px;
//   width: 100%;
// }
//
// #header .reg-buttons {
//   text-align: right;
// }
//
// #header .buttonLight {
//   margin-top: 6px;
//   margin-right: 15px;
// }

.buttonLight,
a.buttonLight {
  font-size: 16px;
  font-size: 1.333em;
  display: inline-block;
  padding: 15px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  color: #008F54;
  border: solid 1px #008F54;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.buttonLight:hover,
a.buttonLight:hover {
  text-decoration: none;
  color: #fefefe;
  background-color: #008F54 !important;
}

.b-body-items {
  padding-left: 25px !important;
}

.b-head__logo {
  max-width: 52px;
  vertical-align: baseline;
}

.b-head__l,
.b-head__r {
  padding-top: 0;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
textarea:focus {
  border-color: #199043;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(25, 144, 67, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(25, 144, 67, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(25, 144, 67, 0.6);
}
/*
#news-line {
  height: 16px;
  width: 100%;

  border: none;
  border-top: 3px solid #F1F667;
  border-bottom: 3px solid #F1F667;

  margin-bottom: 8px;
}
*/
.request-notice {
  text-align: center;
  font-size: 0.8em;
  line-height: 1.2em;
  padding: 0;
  margin: 5px 0;
}

nav .nav-list > .active > a,
nav .nav-list > .active > a:focus,
nav .nav-list > .active > a:hover {
  background-color: @greenLight;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-submenu:focus > a,
.dropdown-submenu:hover > a {
  background-color: @greenLight;
}

.nav .dropdown-toggle .caret,
.nav .dropdown-toggle:hover .caret {
  border-top-color: @greenMain;
  border-bottom-color: @greenMain;
}

.nav.nav-tabs a {
  text-decoration: none;
  cursor: pointer;
}
/*
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: @greenLight;
  background-image: none;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: @greenLight !important;
  background-image: none !important;
}
*/
#informers {
  margin-top: 15px;
}

#informers img {
  display: block;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

#article-block {
  // margin-right: 270px;
}

#body-side-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  padding: 5px;
}

#body-side-left h4 {
  margin: 10px;
}

footer {
  margin-top: 15px;
  padding: 15px;
  font-size: 11px;
  line-height: 13px;
  border-top: 1px solid #CCC;
}

footer .fa {
  font-size: 1.5em !important;
  margin-right: 5px;
}

.mobileLeftNavController {
  position: fixed;
  z-index: 9999;
  transform-origin: 0 100%;
  display: none;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  top: 70px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid @greyMain;
  background-color: @white;
  left: 0;
  margin-left: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -ms-transform-origin: 50% 50%;
  /* IE 9 */
  -webkit-transform-origin: 50% 50%;
  /* Chrome, Safari, Opera */
  transform-origin: 50% 50%;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.mobileLeftNavController_opened {
  margin-left: -40px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  left: 100%;
}

.followMe {
  position: fixed;
  z-index: 100;
  background-color: @white;
  top: 150px;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  left: 0;
}

.followMe .pinSubMenu {
  color: @greenMain;
}

.pinSubMenu {
  float: right;
  font-size: 24px;
  margin-right: 10px;
  color: @greyMain;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.pinSubMenu:hover {
  color: #000;
}

.offers {
  padding-left: 0;
}

.offer-link {
  display: block;
  width: 50%;
  margin: 0 auto;
}

input[type="text"]:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@media print {
  /* Стиль для печати */
  #body-side-left,
  #body-side-right,
  .mobileLeftNavController,
  .no-print,
  footer,
  header,
  nav {
    display: none !important;
    width: 0 !important;
  }

  #top + .container-fluid {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  #main-block {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 30px !important;
  }

  #article-block {
    margin-right: 0;
  }
}

@media (max-width: 990px) {
  .followMe {
    position: static;
    width: auto;
  }

  .pinSubMenu {
    display: none;
  }

  #article-block {
    margin: 0;
  }

  .mobileLeftNavContainer {
    position: fixed;
    width: 100%;
    z-index: 9990;
    background-color: @white;
    top: 0;
    padding-right: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    left: -98%;
    height: 100vh;
    overflow: scroll;
  }
}
@media (max-width: 955px) {
  #header .buttonLight {
    margin-top: 10px;
    margin-right: 10px;
  }

  #header .reg-buttons {
    text-align: center;
  }

  .buttonLight,
  a.buttonLight {
    font-size: 1em;
    padding: 10px;
  }
}

#main-block {
  padding-right: 0px;
  min-width: 250px;
  padding-right: 354px;
}

#article-block {
  margin-right: 0px !important;
}

@media (max-width: 820px) {
  #top + .container-fluid {
    margin-top: 80px;
  }

  #header {
    background-image: none;
    height: auto;
    padding-left: 5%;
  }

  #header .buttonLight {
    margin-top: 10px;
    margin-right: 0;
    margin-left: 10px;
  }

  #header-search-form {
    margin-bottom: 10px;
  }
}
@media (max-width: 780px) {
  #top + .container-fluid {
    margin-top: 125px;
  }

  #header .reg-buttons {
    text-align: left;
  }

  .buttonLight,
  a.buttonLight {
    font-size: 1em;
    padding: 10px;
  }
}
@media (max-width: 750px) {
  #top + .container-fluid {
    margin-top: 125px;
  }

  #header .reg-buttons {
    text-align: center;
  }

  .buttonLight,
  a.buttonLight {
    font-size: 1em;
    padding: 5px;
  }

  #header-links {
    padding: 8px 10px;
  }

  #article-top {
    display: none !important;
  }

  .pagination-sm > li > a,
  .pagination-sm > li > span,
  article nav .pagination .page {
    padding: 2px 4px;
  }
}
@media (max-width: 1150px) {
  .mobileLeftNavContainer {
    position: absolute;
    top: 0;
    left: -96%;
    width: 100%;
    z-index: 5;
    padding-top: 82px;
    background-color: @white;
  }

  .mobileLeftNavController {
    display: block;
    position: absolute;
  }

  #main-block {
    width: 95.7446808511%;
    float: none;
    margin: auto 3%;
  }
}
@media screen and (min-width: 992px) {
}

@media (max-width: 820px) {
  .mobileLeftNavContainer {
    position: fixed;
  }
  .mobileLeftNavController {
    display: block;
    position: fixed;
  }
}
@media (min-width: 1500px) {
  .followMe {
    width: 16.66666667%;
  }
}

.mobileLeftNavContainer_opened {
  left: 0;
  padding-right: 10px;
}

a.btn-success {
  margin-top: 10px;
  color: @white;
}

a.btn-success.active {
  background-color: @greenLight;
}

a.btn-success.active:hover {
  text-decoration: none;
  background-color: @greenMain;
  color: @white;
}

.unstyled {
  list-style: none;
}

.unstyled.pull-right {
  text-align: right;
}
/*Стили динамического баннера Курсов*/
.kursiBG {
  margin-bottom: 10px;
  text-align: center;
  background: url("/img/adv/kursi-subject/pattern.jpg");
  background-repeat: repeat-x;
  width: 100%;
  height: 120px;
}

.kursiBGMain {
  margin-bottom: 10px;
  text-align: center;
  background: url("/img/adv/kursi/pattern.jpg");
  background-repeat: repeat-x;
  width: 100%;
  height: 120px;
}

.kursiLink {
  margin-bottom: 10px;
  text-align: center;
  background-color: #f8f8f8;
  width: 100%;
  height: 120px;
}

.kursiLinkImg {
  width: 600px;
  height: 120px;
  scrolling: no;
  border: none !important;
}

.bannerFrame {
  border: none;
}

.subjectContainer {
  width: 600px;
  height: 120px;
  margin: 0 auto;
  text-align: right;
}

.subjectText {
  font-size: 14px;
  color: #FFF;
  position: relative;
  top: -90px;
  right: 5px;
  display: inline-block;
}

.carouselOuter {
  display: flex;
  justify-content: start;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 300px;
  margin: 20px 0;
  overflow: hidden;
}

.carouselInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 10px;
}

.carouselOuter .carousel-slide {
  position: relative;
  min-width: 300px;
  max-width: 420px;
  width: auto;
  height: 300px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #42909d;
  color: white;
  box-sizing: border-box;
}

.carousel-slide .carousel-caption {
  position: absolute;
 /* top: -20px;*/
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  padding: 0!important;
}

.carousel-slide a {
  color: white;
  text-decoration: none;
}

.carousel-slide .carousel-caption h4 {
  font-size: 15px;
  color: white;
}

.carousel-slide .carousel-caption p {
  padding: 0 8px;
}

.carousel-slide .carousel-caption .carousel-image {
  position: relative;
  display: inline-block;
  width: 85%;
  height: 170px;
  margin: 10px auto 0 auto;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-image .carousel-coursePrice {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: #42909d;
  padding: 6px 12px;
  font-size: 15px;
}

.fa-angle-left,
.fa-angle-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px;
  color: #333;
  opacity: 0.7;
  cursor: pointer;
  z-index: 1000;
}

.fa-angle-left:hover,
.fa-angle-right:hover {
  opacity: 1;
}

.fa-angle-left {
  left: 22px;
}

.fa-angle-right {
  right: 22px;
}

.controls {
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 18;
  background: linear-gradient(to right, rgba(255,255,255,.5) 20%,rgba(0,0,0, .2) 100%);
}

.controls:first-of-type {
  left: 0;
  background: linear-gradient(to left, rgba(255,255,255,.5) 20%,rgba(0,0,0, .2) 100%);
}

.controls.transparent {
  background: transparent;
  cursor: default;
}

.controls.transparent .fa {
  color: transparent;
  cursor: default;
}

/*карусель с курсами в боковом меню*/
#myCarousel {
  margin-top: 20px;
}

#myCarousel .item {
  height: 315px;
}

#myCarousel .item .carousel-caption {
  width: 100%;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0 !important;
}

.carousel-inner .item {
  background-color: #42909d;
}

#myCarousel .item .carousel-caption h4 {
  margin: 0 0 10px;
  font-size: 14px;
}

#myCarousel .item .carousel-caption .carousel-image {
  position: relative;
  width: 85%;
  height: 150px;
  margin: 0 auto 2%;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-image .carousel-coursePrice {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: #42909d;
  padding: 6px 12px;
  font-size: 15px;
}

#myCarousel .item .carousel-caption p {
  padding: 0 5px;
}

#myCarousel .left.carousel-control {
  left: -15px;
}

#myCarousel .right.carousel-control {
  right: -15px;
}

@media screen and (max-width: 500px) {
  .carouselInner {
    width: 100%;
  }

  .carouselInner .carousel-slide {
    display: none;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .carousel-slide.active {
    display: inline-block;
  }
  @keyframes swiftRight {
    0% {
      margin-left: 200%;
    }

    100% {
      margin-left: 0;
    }
  }
  @keyframes swiftLeft {
    0% {
      margin-left: -200%;
    }

    100% {
      margin-left: 0;
    }
  }
}
// медиа-запросы для каруселей с курсами
@media screen and (min-width:768px) and (max-width:991px) {
  #myCarousel .left.carousel-control {
    left: -35px;
  }

  #myCarousel .right.carousel-control {
    right: -35px;
  }

  #myCarousel .left.carousel-control .glyphicon-chevron-left,
  #myCarousel .right.carousel-control .glyphicon-chevron-right {
    font-size: 30px!important;
  }
}
@media screen and (max-width:1230px) {
  #myCarousel .carousel-caption {
    padding-top: 5px;
  }
}
#myCarousel .item h4 {
  font-size: 13px;
}
@media screen and (min-width:1500px) and (max-width:1837px) {
  #myCarousel .item h4 {
    font-size: 14px;
  }
}
// медиа-запросы для шапки
@media screen and (max-width: 530px) {
    .logo {
        width: 65%;
        vertical-align: top;
        .img_fpg {
            margin-top: 5px;
        }
    }
    .reg-buttons {
        width: 33%;
    }
}
@media screen and (min-width: 531px) and (max-width: 820px) {
    .logo {
        width: 48%;
    }
    .reg-buttons {
        width: 50%;
    }
}
@media screen and (min-width: 821px) {
    #header {
        .logo {
            width: 40%;

        }
        .reg-buttons {
            width: 38%;
        }
        .offOnMobile {
            max-width: 20%;
            width: auto;
            position: absolute;
            right: 0;
        }
    }

}
@media screen and (max-width: 927px) {
    #header .img {
        height: 35px;
        margin-top: 15px;
    }
    #header .img.img_fpg {
        margin-top: 5px;
    }
}
@media screen and (min-width: 928px) and (max-width: 1212px) {
    #header .img {
        height: 45px;
        margin-top: 10px;
    }
    #header .img.img_fpg {
        margin-top: 0;
    }
}

@media screen and (min-width: 1213px) {
    #header .img.img_fpg {
        margin-top: -7px;
    }
}
